.admin-chat-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: calc(100vh - 70px);
}

.user-list {
  width: 25%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  background-color: #f8f8f8;
  height: calc(100vh - 70px);
}

.user-list .ant-input, .user-list .ant-input-group-addon {
  background: transparent;
  border: none;
  border-bottom: solid 1px white;
  color: white;
  border-radius: 0;
  outline: none;
}

.user-list .ant-input:focus,
.user-list .ant-input-focused {
  border: none;
  outline: none;
  box-shadow: none;
}

.custom-search-input input::placeholder {
  color: white !important;
}

.user-list-head{
  height: 90px;
  background-color:  #001529;
  color: white;
  padding: 10px;
}

.user-list-items{
  overflow: auto;
  height: calc(100% - 90px);
  padding: 10px 5px;
}

.user-item {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.user-avatar{
  flex-shrink: 0;
}

.user-item:hover, .user-item.selected {
  background-color: #e0e0e0;
}

.admin-chat-container .chat-container {
  width: 75%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
}

.admin-chat-container .messages {
  padding: 20px;
  overflow-y: scroll;
  display: flex;
  height: calc(100% - 60px);
  flex-direction: column;
  background-color: #f1f1f1;
}

.admin-chat-container .box{
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-chat-container .message-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  display: inline-block;
  word-wrap: break-word;
}

.admin-chat-container .user-message {
  background-color: #b8d6ff;
  align-self: flex-start;
}

.admin-chat-container .sent {
  background-color: #b8d6ff;
  align-self: flex-end;
}

.admin-chat-container .received {
  background-color: #fff;
  align-self: flex-start;
}

.response-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #fff;
  height: 60px;
}

.admin-chat-container .send-btn {
  height: 100%;
}

.admin-chat-container .input-container {
  display: flex;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.response-input .ant-input {
  flex-grow: 1;
  margin-right: 10px;
}

.send-btn{
  width: fit-content;
  background-color: #001529;
  border: none;
}

.no-user-selected {
  padding: 20px;
  text-align: center;
  color: #888;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.admin-chat-container .logout-button {
  display: block;
  margin-bottom: 20px;
  background-color: #f44336;
  color: white;
  border: none;
  width: 100%;
}

.logout-button, .send-btn {
  transition: none !important;
}

.logout-button:hover{
  background-color: #f44336 !important;
  border-color: unset !important;
  color: white !important;
  box-shadow: unset !important;
}
.send-btn:hover {
  background-color: #001529 !important;
  border-color: unset !important;
  color: white !important;
  box-shadow: unset !important;
}

.message-time {
  font-size: 0.75em;
  color: #888;
  margin-top: 5px;
  align-self: flex-end;
}

.message-date {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #666;
}

.admin-chat-container .chat-head{
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #002140;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  color: white;
  align-items: center;
}

.admin-chat-container .chat-head > div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.admin-chat-container .chat-head > div > h3{
  padding-top: 5px;
}



/* @media (max-width: 768px) {
  .admin-chat-container {
    flex-direction: column;
  }
  .user-list {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
  .chat-container {
    width: 100%;
  }
} */
