/* src/styles/InteractiveMapWidget.css */

.interactive-map-widget {
    height: 100%;
  }
  
  .map-container {
    height: 200px; /* Set a fixed height for the map */
    border-radius: 8px;
    overflow: hidden;
  }
  