/* src/styles/RecentProjectsWidget.css */

.recent-projects-card {
  /* width: 100%; */
  background: transparent;
}

.project-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.ant-list-item-meta-title a {
  color: #3A3A8D; /* Primary color */
  font-weight: bold;
  font-size: 1.1em;
}

.ant-list-item-meta-description {
  font-size: 0.9em;
  color: #555; /* Secondary text color */
}

.ant-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
}

.ant-card-head-title {
  font-size: 1.2em;
  color: #3A3A8D; /* Primary color */
  font-weight: bold;
}

.project-card {
  display: flex;
  align-items: center;
}

.project-card .ant-card-body {
  display: flex;
  align-items: center;
  padding: 16px;
}
