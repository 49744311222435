/* src/styles/SliderCarousel.css */
.carousel-card {
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  /* border-radius: 10px; */
  height: fit-content;
}
.carousel-card .ant-card-body {
  padding: 0;
}

.carousel-slide {
  position: relative;
  text-align: center;
}

.carousel-image {
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 500px;
  object-fit: cover;
  /* border-radius: 10px; */
}
/* .carousel-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay */
/* } */

.skeleton-carousel {
  width: 100%;
  height: calc(500px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 10%;
}

.skeleton-form {
  width: 40%;
  position: absolute;
  right: 30px;
  top: 5%;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.774);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1;
}

.cta-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.753);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.cta-form-container h2 {
  margin-bottom: 20px;
  color: #3a3a8d;
}

.cta-container {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}

@media (max-width: 770px) {
  .get-quote-button {
    font-size: 18px;
    padding: 12px;
    margin-top: 20px;
    height: fit-content;
    background-color: #001529;
  }

  .cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
  }
}

/* Professional Carousel Styles */
.professional-carousel {
  position: relative;
  overflow: hidden;
  /* border-radius: 16px; */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}
.professional-carousel .ant-card-body{
  padding: 0;
}

.carousel-container {
  position: relative;
  height: 100vh;
  min-height: 600px;
}

.hero-carousel .carousel-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.85);
  transition: transform 0.5s ease;
}

/* Animated Caption */
.animated-caption {
  position: absolute;
  bottom: 8vh;
  margin: auto;
  width: 100%;
  color: white;
  animation: slideInLeft 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.caption-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.719);
  width: fit-content;
  padding: 6px 10px;
  margin: 0 auto;
  opacity: 0;
  animation: fadeInUp 0.8s 0.3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

/* Floating Form */
.floating-form-container {
  position: absolute;
  top: 150px;
  right: 5%;
  z-index: 2;
  width: 300px;
  animation: formEntrance 0.6s 0.2s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.compact-quote-form {
  background: rgba(255, 255, 255, 0.98);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.form-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #001529;
  text-align: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .professional-carousel {
    margin: 0;
    border-radius: 0;
  }

  .carousel-container {
    height: 80vh;
    min-height: 400px;
  }

  .hero-carousel .carousel-image {
    height: 80vh;
  }

  .animated-caption {
    text-align: center;
    bottom: 70px;
  }

  .caption-title {
    font-size: 0.8rem;
  }

  .mobile-cta-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    height: 48px;
    z-index: 100;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
}

/* Animations */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-40px) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes formEntrance {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form Elements */
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  border-radius: 8px !important;
}

.ant-btn-primary {
  height: 42px;
  border-radius: 8px;
  font-weight: 500;
}
