/* src/styles/Chatbot.css */

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.chat-header {
  background-color: #001529;
  color: white;
  padding: 16px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.chat-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.message {
  margin-bottom: 12px;
  display: flex;
}

.message.user {
  justify-content: flex-end;
}

.message-content {
  max-width: 70%;
  padding: 10px 16px;
  border-radius: 20px;
  background-color: #001529;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-content.user {
  background-color: #e6f7ff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-input-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}

.chat-input {
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s ease;
}

.chat-input:focus {
  border-color: #001529;
}

.chat-send-button {
  margin-left: 10px;
  background-color: #001529;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-send-button:hover {
  background-color: #1890ff;
}
