.login-container {
    max-width: 400px;
    margin: 20vh auto;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

.login-title {
    text-align: center;
    margin-bottom: 24px;
}

.login-form {
    width: 100%;
}

.login-form-button {
    width: 100%;
}

.site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
}
