/* src/App.css */
.site-layout-background {
  background: transparent;
  color: #fff;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.logo img {
  width: 40px;
  height: auto;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/* @media (min-width: 768px) {
  .site-layout-background .ant-btn {
    display: none;
  }
} */

@media (max-width: 767px) {
  .site-layout-background .ant-menu-horizontal {
    display: none;
  }
}
