.about-header {
  background-color: #001529;
  padding: 0 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.about-content {
  padding: 50px 5%;
  /* background: linear-gradient(135deg, #f0f9ff, #cbebff); */
  /* animation: backgroundAnimation 10s infinite alternate; */
}

/* @keyframes backgroundAnimation {
    0% {
      background: linear-gradient(135deg, #f0f9ff, #cbebff);
    }
    100% {
      background: linear-gradient(135deg, #cbebff, #f0f9ff);
    }
  } */

.about-card,
.about-team-card {
  padding: 24px 5%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.about-icon {
  font-size: 36px;
  color: #1890ff;
  margin-bottom: 16px;
}

.about-team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
