.contact-header {
  background-color: #001529;
  padding: 0 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.contact-content {
  padding: 50px 5%;
  /* background: linear-gradient(135deg, #e0f7fa, #7dabb1);
    animation: backgroundAnimation 10s infinite alternate; */
}

/* @keyframes backgroundAnimation {
    0% {
      background: linear-gradient(135deg, #e0f7fa, #80deea);
    }
    100% {
      background: linear-gradient(135deg, #80deea, #e0f7fa);
    }
  } */

.contact-form {
  padding: 24px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.contact-info {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  -webkit-backdrop-filter: blur(10px);
}
