/* Main header styles */
.main-header {
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 24px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  height: 80px;
  gap: 24px;
}

/* Logo styles */
.logo-container {
  flex-shrink: 0;
}

.header-logo {
  transition: all 0.3s ease;
}

/* Desktop menu styles */
.desktop-menu-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 16;
}

.main-menu {
  flex: 1;
  min-width: 0;
  max-width: calc(100% - 60px);
  background: transparent !important;
  border-bottom: none !important;
  justify-content: flex-start;
}

/* Mobile menu styles */
.mobile-menu-container {
  margin-left: auto;
  display: flex;
  gap: 8px;
}

/* Language button styles */
.language-button {
  flex-shrink: 0;
}

/* Menu item overflow styling */
.ant-menu-overflow-item-rest {
  display: flex !important;
  align-items: center;
}

.ant-menu-horizontal > .ant-menu-overflow-item .ant-menu-submenu-title {
  padding: 0 15px !important;
}

.ant-menu-horizontal > .ant-menu-overflow-item .ant-menu-submenu-arrow {
  display: none !important;
}

/* Dropdown menu styling */
.ant-dropdown-menu {
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  padding: 8px 0 !important;
}

.ant-dropdown-menu-item {
  padding: 8px 16px !important;
  transition: all 0.3s ease !important;
}

.ant-dropdown-menu-item a {
  display: flex !important;
  align-items: center !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-dropdown-menu-item a:hover {
  color: #1890ff !important;
  background: transparent !important;
}

/* Badge positioning */
.ant-badge-dot {
  top: 6px !important;
  right: 6px !important;
}

/* Scroll transitions */
@media (min-width: 768px) {
  .main-header {
    padding: 16px 24px !important;
  }

  .main-header.scrolled {
    padding: 0 24px !important;
  }
}
