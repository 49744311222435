/* ServicePage.css */

.service-header {
    background-color: #001529;
    padding: 0 50px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service-content {
    padding: 50px 5%;
    /* background: linear-gradient(135deg, #f0f9ff, #cbebff);
    animation: backgroundAnimation 10s infinite alternate; */
  }
  
  /* @keyframes backgroundAnimation {
    0% {
      background: linear-gradient(135deg, #f0f9ff, #cbebff);
    }
    100% {
      background: linear-gradient(135deg, #cbebff, #f0f9ff);
    }
  } */
  
  .service-card, .service-team-card {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .service-icon {
    font-size: 36px;
    color: #1890ff;
    margin-bottom: 16px;
  }
  
  .service-team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  