/* src/styles/AdvantagesWidget.css */
.advantages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: transparent;
}

.advantage-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 calc(30% - 32px);
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 16px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.advantage-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.advantage-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 16px; /* Rounded corners for the image */
  border-top-right-radius: 16px;
}

@media (max-width: 768px) {
  .advantage-card {
    flex: 1 1 calc(50% - 32px);
  }
}

@media (max-width: 480px) {
  .advantage-card {
    flex: 1 1 100%;
  }
}
