.site-layout.expanded .content {
  margin-left: 200px; /* Width when the Sider is expanded */
  transition: margin-left 0.2s;
}

.site-layout.collapsed .content {
  margin-left: 80px; /* Width when the Sider is collapsed */
  transition: margin-left 0.2s;
}

.site-layout .site-layout-background {
  background: #fff;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  width: fit-content;
}

.trigger:hover {
  color: #1890ff;
}

@media (max-width: 1024px) {
  .site-layout.expanded .content,
  .site-layout.collapsed .content {
    margin-left: 80px;
  }
}

@media screen and (max-width: 990px) {
  .sider {
    position: fixed;
    height: 100%;
  }
}