/* src/styles/FloatingButton.css */

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #1890ff;
    border-color: #1890ff;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .floating-button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
    transform: translateY(-2px);
  }
  
  .slide-panel {
    position: fixed;
    top: 0;
    right: -400px;
    width: fit-content;
    height: 100%;
    background: transparent;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease;
    z-index: 1000;
  }
  
  .slide-panel.visible {
    right: 0;
  }
  
  .slide-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #001529;
    color: white;
  }
  
  .close-button {
    font-size: 20px;
    line-height: 20px;
    color: white;
  }

  .close-button:hover{
    color: red !important;
  }
  
  .slide-panel-content {
    padding: 10px;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }