/* src/styles/Products.css */

.header {
    background: #001529;
    padding: 0;
    color: white;
  }
  
  .search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    max-width: 500px;
  }
  
  .product-card {
    transition: transform 0.3s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .filtered-products{
    overflow-y: auto;
    height: calc(100vh - 150px);
  }
  .filtered-products::-webkit-scrollbar{
    width: 10px;
  }
  .filtered-products::-webkit-scrollbar-track{
    background-color: #d4d4d467;
    border-radius: 10px;
  }
  .filtered-products::-webkit-scrollbar-thumb{
    background-color: #949494a4;
    border-radius: 10px;
  }
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
