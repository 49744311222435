/* src/styles/LiveChatWidget.css */
.live-chat-container {
  display: flex;
  flex-direction: column;
  height: 500px; /* Adjust height as needed */
  border-radius: 8px;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.message {
  margin-bottom: 8px;
  display: flex;
}

.message.user {
  justify-content: flex-end;
}

.message-content {
  max-width: 70%;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #1890ff;
  color: white;
}

.message-content.user {
  background-color: #f0f0f0;
  color: #333;
}

.chat-input-container {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 8px;
  background-color: #fff;
  position: sticky; /* Ensures the input stays fixed at the bottom */
  bottom: 0;
}

.chat-input {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 0;
  outline: none;
}

.chat-send-button {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 0 16px;
  cursor: pointer;
}

.chat-send-button:hover {
  background-color: #40a9ff;
}

.chat-options {
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
  border-top: 1px solid #ddd;
  background-color: #fff;
}
