/* src/styles/FeaturedProductsWidget.css */
.featured-products-card {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  border-radius: 10px;
  box-shadow: 
    0 4px 30px rgba(0, 0, 0, 0.1),
    0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0;
  min-height: 400px;
}

.featured-products-card .ant-card {
  padding: 20px;
}

.featured-products-card .ant-card-body{
  padding: 24px 0;
}

.product-card {
  border-radius: 10px;
  padding: 0;
  margin: 10px;
}

.product-image {
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  padding: 0;
}

.product-details {
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 18px;
  color: #E52027;
  margin-bottom: 10px;
}

.buy-button {
  margin-top: 10px;
  color: #3A3A8D;
  background-color: transparent;
  border-color: #3A3A8D;
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  color: #3A3A8D;
}

.rate-md .ant-rate-star {
  font-size: 24px; /* Default size for medium and larger screens */
}

.rate-sm .ant-rate-star {
  font-size: 10px; /* Smaller size for small screens */
}
