/* src/styles.css */
body {
    background: transparent;
  }
  
  .ant-layout-header {
    background-color: #001529;
  }
  
  .ant-layout {
    background: transparent;
  }
  
  .ant-card {
    /* margin-top: 20px; */
  }
  
  .ant-table {
    margin-top: 20px;
  }
  