.quote-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.quote-form .ant-form-item {
    margin-bottom: 16px;
}


.quote-form .ant-btn {
    height: 40px;
    font-size: 16px;
}
