/* src/styles/Chat.css */
.user-chat-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 210px);
  margin-top: 30px;
  margin-bottom: 30px;
  
}

.user-chat-container .messages {
  padding: 20px;
  overflow-y: scroll;
  display: flex;
  height: calc(100% - 60px);
  flex-direction: column;
}


.user-chat-container .box {
  height: calc(100vh - 270px);
  width: 90%;
  max-width: 500px;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  background-color: #f1f1f1;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.295);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding-top: 10px;
}

.user-chat-container .message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message {
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.sent {
  background-color: #b8d6ff;
  align-self: flex-end;
}

.received {
  background-color: #fff;
  align-self: flex-start;
}

.admin-message {
  background-color: #f5f5f5;
}

.admin-response {
  background-color: #fff1f0;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
  max-width: 60%;
  align-self: flex-end;
}

.input-container .ant-input {
  flex-grow: 1;
  margin-right: 10px;
}

.user-chat-container .input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #fff;
  height: 60px;
}
.user-chat-container .send-btn {
  height: 100%;
}

.user-chat-container .chat-head{
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #001529;
  height: 50px;
  width: 90%;
  max-width: 500px;
  justify-content: space-between;
  color: white;
  align-items: center;
  border-radius: 12px 12px 0 0;
}

.user-chat-container .chat-head > div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.user-chat-container .chat-head > div > h3{
  padding-top: 5px;
}

.user-chat-container .logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  width: fit-content;
  z-index: 1;
  padding: 10px;
}

.logout-button, .send-btn {
  transition: none !important;
}

.logout-button:hover{
  background-color: #f44336 !important;
  border-color: unset !important;
  color: white !important;
  box-shadow: unset !important;
}
.send-btn:hover {
  background-color: #001529 !important;
  border-color: unset !important;
  color: white !important;
  box-shadow: unset !important;
}

.message-time {
  font-size: 0.75em;
  color: #888;
  margin-top: 5px;
  align-self: flex-end;
}

.date-separator {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #666666;
}

.admin-status {
  margin-left: auto;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.online-status {
  color: green;
}

.offline-status {
  color: red;
}
