/* src/styles/FeaturedProductInfo.css */
.product-info-card {
  max-width: 600px;
  margin: 20px auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  box-shadow: 
    0 4px 30px rgba(0, 0, 0, 0.1),
    0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.product-info-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.product-info-description {
  margin: 20px 0;
  font-size: 16px;
  line-height: 1.5;
}

.product-info-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.product-info-price {
  font-size: 24px;
  font-weight: bold;
  color: #fa541c;
}

.back-button {
  display: block;
  margin: 0 auto;
}

.loading-container, .error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.error-container p {
  font-size: 18px;
  color: #ff4d4f;
  margin-bottom: 20px;
}
