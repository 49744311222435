/* src/styles/VideoGalleryWidget.css */

.video-gallery-card {
    background: white;
  }
  
  .video-container {
    position: relative;
  }
  
  .video-container .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .video-title {
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  